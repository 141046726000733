@font-face {
  font-family: "MaisonNeue";
  src: url("/fonts/MaisonNeue-Light.woff2") format("woff2");
  src: url("/fonts/MaisonNeue-Light.woff") format("woff");
  font-weight: 200;
}
@font-face {
  font-family: "MaisonNeue";
  src: url("/fonts/MaisonNeue-Demi.woff2") format("woff2");
  src: url("/fonts/MaisonNeue-Demi.woff") format("woff");
  font-weight: 400;
}

body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}
